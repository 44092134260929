/* global google:true */
/* eslint-disable camelcase */
/*
 * This sample uses the Autocomplete widget to help the user select a
 * place, then it retrieves the address components associated with that
 * place, and then it populates the form fields with those details.
 * This sample requires the Places library. Include the libraries=places
 * parameter when you first load the API. For example:
 * <script
 * src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places">
 */

/* global Rollbar */
import $ from 'jquery';

const autocompleteFields = [];

const componentForm = {
    subpremise: 'short_name',
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
};

/*
 * Bias the autocomplete object to the user's geographical location,
 * as supplied by the browser's 'navigator.geolocation' object.
 */
const geolocate = autocompleteField => {
    const geolocation = {
        lat: 49.8951,
        lng: -97.1384
    };
    const circle = new google.maps.Circle({
        center: geolocation,
        radius: 100000
    });
    autocompleteField.setBounds(circle.getBounds());
};

const setFields = ($form, address) => {
    let street = '';

    if (address.subpremise) {
        street += `${address.subpremise}-`;
    }

    street += `${address.street_number} ${address.route}`;
    $form.find('.address_line1').val(street);
    $form.find('.address_city').val(address.locality);
    $form.find('.address_province').val(address.administrative_area_level_1);
    $form.find('.address_postal_code').val(address.postal_code);
};

const fillInAddress = (autocompleteField, $form) => {
    // Get the place details from the autocomplete object.
    const place = autocompleteField.getPlace();
    const address = {};

    /*
     * Get each component of the address from the place details,
     * and then fill-in the corresponding field on the form.
     */
    for (const component of place.address_components) {
        const addressType = component.types[0];

        if (componentForm[addressType]) {
            address[addressType] = component[componentForm[addressType]];
        }
    }

    setFields($form, address);

    $form.find('.address_lookup').val('');
};

const initAutocomplete = (index, field) => {
    /*
     * Create the autocomplete object, restricting the search predictions to
     * geographical location types.
     */
    autocompleteFields[index] = new google.maps.places.Autocomplete(field,
        {types: ['geocode'], strictBounds: true});
    geolocate(autocompleteFields[index]);

    const $fieldset = $(field).closest('fieldset');

    /*
     * Avoid paying for data that you don't need by restricting the set of
     * place fields that are returned to just the address components.
     */
    autocompleteFields[index].setFields(['address_component']);
    /*
     * When the user selects an address from the drop-down, populate the
     * address fields in the form.
     */
    autocompleteFields[index].addListener('place_changed', () => {
        fillInAddress(autocompleteFields[index], $fieldset);
    });
};

$(document).on('turbolinks:load', () => {
    const RETRY_DELAY = 500; // milliseconds
    const MAX_ATTEMPTS = 10; // 5 seconds / 500ms = 10 attempts
    const MAX_DELAY = RETRY_DELAY * MAX_ATTEMPTS / 1000; // seconds
    let attempts = 0;

    const initIfGoogleDefined = () => {
        if (typeof google === 'undefined') {
            attempts++;

            if (attempts < MAX_ATTEMPTS) {
                setTimeout(initIfGoogleDefined, RETRY_DELAY);
            }
            else {
                console.error(`Google API not loaded after ${MAX_DELAY} seconds.`); // eslint-disable-line no-console
                Rollbar.error(`Google API not loaded after ${MAX_DELAY} seconds.`);
            }
        }
        else {
            $('.address_lookup').each((index, field) => {
                initAutocomplete(index, field);
            });
        }
    };

    initIfGoogleDefined();
});